import Vue from 'vue';
import Vuex from 'vuex';
import { MockHandler } from './mockHandler';
import {Store} from './store'
import createPersistedState from 'vuex-persistedstate'

const store = new Store()
Vue.use(Vuex);

const dataState = createPersistedState({
  paths: ['token', 'tokenExpires', 'currencies', 'countries', 'account'] // props to be persistently stored
})
export default new Vuex.Store({
  plugins: [dataState],
  actions : {
    updateBillingDetails: store.ACTION__updateBillingDetails,
    getBillingDetails: store.ACTION__getBillingDetails,
    initApi:store.ACTION__initApi,
    autoRefreshToken: store.ACTION__autoRefreshToken,
    login: store.ACTION__login,
    logout: store.ACTION__logout,
    registerSimplyVideoAdminMagicToken: async ({commit, dispatch}: any, token: string ) => {
      return await store.pp.registerSvAdminMagicToken({token}).catch((err) => {
        commit('flag_oopsy', err)
      });
    },
    updatePassword: async ({commit, dispatch}: any, {password, passwordConfirmation}: any) => {
      return store.pp.updatePassword({
        updatePassword:{
          password,
          password_confirmation: passwordConfirmation,
        }
      }).catch((err) => {
        commit('flagOopsy', err)
      });
    },

    registerSimplyVideoAdmin: store.ACTION__registerSimplyVideoAdmin,
    refreshToken: store.ACTION__refreshToken,
    getProfile: store.ACTION__getProfile,
    updateProfile: store.ACTION__updateProfile,
    updateEmail: store.ACTION__updateEmail,
    setPrevRoute({commit}, prevRoute) {
      commit('setPrevRoute', prevRoute);
    },
    validateForgotPasswordCode: store.ACTION__validateForgotPasswordCode,
    getStatistics: store.ACTION__getStatistics,
    inviteSvAdmin: store.ACTION__inviteSvAdmin,
    getSvAdmins: store.ACTION__getSvAdmins,
    distributorRegistrationValidateToken: store.ACTION__distributorRegistrationValidateToken,
    distributorRegistrationStepOne: store.ACTION__distributorRegistrationStepOne,
    distributorRegistrationStepTwo: store.ACTION__distributorRegistrationStepTwo,
    distributorRegistrationStepThree: store.ACTION__distributorRegistrationStepThree,
    distributorRegistrationSetupPayment: store.ACTION__distributorRegistrationSetupPayment,
    distributorRegistrationStepFour: store.ACTION__distributorRegistrationStepFour,
    getListOfDistributors: store.ACTION__getListOfDistributors,
    getListOfResellers: store.ACTION__getListOfResellers,
    getListOfCustomers: store.ACTION__getListOfCustomers,
    applyForDistributorAccount: store.ACTION__applyForDistributorAccount,
    getAccount: store.ACTION__getAccount,
    getCurrencies: store.ACTION__getCurrencies,
    getCountries: store.ACTION__getCountries,
    cancelLicenseChange: store.ACTION__cancelLicenseChange,
    validateApplicationEmail: store.ACTION__validateApplicationEmail,
    forgotPassword: store.ACTION__forgotPassword,
    resetPassword: store.ACTION__resetPassword,
    getDistributor: store.ACTION__getDistributor,
    getCompany: store.ACTION__getCompany,
    getListOfDistributorApplications: store.ACTION__getListOfDistributorApplications,
    getDistributorApplication: store.ACTION__getDistributorApplication,
    approveDistributorApplication: store.ACTION__approveDistributorApplication,
    denyDistributorApplication: store.ACTION__denyDistributorApplication,
    addLicensesToDistributorAccount: store.ACTION__addLicensesToDistributorAccount,
    addLicensesToCompanyAccount: store.ACTION__addLicensesToCompanyAccount,
    getListOfAssingableLicenses: store.ACTION__getListOfAssingableLicenses,
    getListOfLicensesAssociatedWithAnAccount: store.ACTION__getListOfLicensesAssociatedWithAnAccount,
    initStripe: store.ACTION__initStripe,
    getDistributorAccount: async ({commit}, id: number) => {
      const {data}: any = await store.pp.svAdminDistributor({id}).catch(({response}) => {
        commit('flag_oopsy', response.data)
        return response
      })
      return data
    },
    getPaymentMethod: store.ACTION__getPaymentMethod,
    updatePaymentMethod: store.ACTION__updatePaymentMethod,
    removePaymentMethod: store.ACTION__removePaymentMethod,
    editDistributorAccount: store.ACTION__editDistributorAccount,

    /* Distributor Endpoints */
    inviteResellers: store.ACTION__inviteResellers,
    getResellerAccount: store.ACTION__getDistributorResellersId,
    getCompanyAccount: store.ACTION__getCompanyAccount,
    getBillingHistory: store.ACTION__getBillingHistory,
    

    /* Reseller / Customer Endpoints */
    createCustomerAccount: store.ACTION__createCustomerAccount,
    updateCustomerAccount: store.ACTION__updateCustomerAccount,
    updateCustomerEmail: store.ACTION__updateCustomerEmail,
    setUserCache: store.ACTION__setUserCache,
    deleteBrandingImage: store.ACTION__deleteBrandingImage,
    restoreDefaultBranding: store.ACTION__restoreDefaultBranding,
    resendCustomerUserInvite: store.ACTION__resendCustomerUserInvite,
    deleteCustomerUser: store.ACTION__deleteCustomerUser,
    inviteCustomerUser: store.ACTION__inviteCustomerUser,
    resellerRegistrationCodeValidation: store.ACTION__resellerRegistrationCodeValidation,
    resellerRegistration: store.ACTION__resellerRegistration,
    resellerRegistrationStepOne: store.ACTION__resellerRegistrationStepOne,
    resellerRegistrationStepTwo: store.ACTION__resellerRegistrationStepTwo,
    getUpcomingInvoice: store.ACTION__getUpcomingInvoice,

    extendTrialLicence: store.ACTION__extendTrialLicence,

    setSending: store.ACTION__setSending,
  },
  state: {
    __PACKAGE_VERSION__: process.env.PACKAGE_VERSION,
    /** Auth token */
    token: '',
    /** Auth token expire in time */
    tokenExpires: 0,
    /** @todo */
    status: '',
    /** @todo */
    prev_route: {},
    /** The list of child acounts that are associated with this account */
    accounts: [],
    /** This account info */
    account: {
      role: undefined,
    },
    /** Generic Error Flag */
    oops: "",
    currencies: [],
    countries: [],
    loading: false,
    sending: false,
  },
  mutations: {
    set_prev_route(state, data) {
      state.prev_route = data;
    },
    set_token: store.MUTATION__set_token,
    set_token_time: store.MUTATION__set_token_time,
    set_account(state, account) {
      
      //@ts-ignore
      state.account = {...state.account,...account};
    },
    set_currencies(state, currencies) {
      state.currencies = currencies;
    },
    set_countries(state, cunt){
      state.countries = cunt
    },
    flush_auth(state) {
      state.token = '';
      state.tokenExpires = 0;
      state.account = {
        role: undefined
      };
    },
    flag_oopsy(state, err){
      state.oops = err
    },
    loading(state, bool){
      state.loading = bool
    },
    sending(state, bool){
      state.sending = bool
    }
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    typeOfAccount: ({ account }) => account.role,
    getCurrencies: (state) => state.currencies,
    isLoading: (state) => state.loading,
    isAdmin: store.GETTER__isAdmin,
    isDistributor: store.GETTER__isDistributor,
    isReseller: store.GETTER__isReseller,
    isSending: (state) => state.sending,
  },
  modules:{
    mock: MockHandler
  }
});