import Component from 'vue-class-component';
import Vue from 'vue';
import {
  State,
  Getter,
  Action,
  Mutation,
} from 'vuex-class'
import { ProvisioningPortal, ProvConfig, SV, SaasConfig, Types } from '@simply-video/web-client';
import { ActionContext } from 'vuex';
export { Types }

export type TAwait<T> = T extends PromiseLike<infer U> ? U : T
export type TElement<T> = T extends (infer U)[] ? U : never;
export type TForm<T> = { // Takes a given object and converts it into a Form style object
  [K in keyof T]: {
    /**
     * The input field value to be submitted
     */
    value: T[K]
    /**
     * The errors associated with this value
     */
    errors: string[] // errors associated with this input field
  }
}

import { loadStripe } from '@stripe/stripe-js/pure';
import { Stripe, StripeCardElement } from '@stripe/stripe-js';

let SAAS_basePath = process.env.VUE_APP_API_URL;

if(!SAAS_basePath){        
  SAAS_basePath = 'https://services.svcore.io/api';
  //SAAS_basePath = 'https://api' + window.location.host.replace('portal', '') + '/api';
}
let PP_basePath = SAAS_basePath + '/provisioning-portal';


export const PP_CONFIG = new ProvConfig({
  basePath: PP_basePath, //PP_basePath,
});

export const SV_CONFIG = new SaasConfig({
  basePath: SAAS_basePath,
});

@Component
export class Store extends Vue {

  pp = new ProvisioningPortal(PP_CONFIG);
  sv = new SV(SV_CONFIG)

  private stripe?: Stripe;

  @State('__PACKAGE_VERSION__') __version!: string
  /** Data in transit booleans */
  @Getter isLoading!: boolean
  @Getter isSending!: boolean

  /** The type of account */
  @Getter typeOfAccount!: Types.Role
  @Getter isAdmin!: boolean
  GETTER__isAdmin(state: any) {
    return state.account.role == Types.Role.SvAdmin
  }
  @Getter isDistributor!: boolean
  GETTER__isDistributor(state: any) {
    return state.account.role == Types.Role.Distributor
  }
  @Getter isReseller!: boolean
  GETTER__isReseller(state: any) {
    return state.account.role == Types.Role.Reseller
  }

  /** This account info */
  @State('account') account!: TAwait<ReturnType<typeof ProvisioningPortal.prototype.getProfile>>['data']
  @State('countries') countries!: TAwait<ReturnType<typeof SV.prototype.getCountries>>['data']
  /** Auth token */
  @State('token') token!: string
  /** Auth token expire in time */
  @State('expiredIn') tokenExpires!: string
  @Getter isLoggedIn!: boolean
  @Mutation('set_token') private set_token!: void
  MUTATION__set_token(state: any, token: string) {
    state.token = token

    PP_CONFIG.accessToken = token
    SV_CONFIG.accessToken = token
  }

  @Action('setSending') setSending!: (value: boolean) => void
  ACTION__setSending({ commit }: ActionContext<any, any>, value: boolean) {
    commit('sending', value);
  }

  @Action('initApi') initApi!: () => void
  ACTION__initApi({ commit, dispatch, state }: ActionContext<any, any>) {
    PP_CONFIG.accessToken = state.token
    SV_CONFIG.accessToken = state.token
  }

  @Mutation('set_token_time') private set_token_time!: void
  MUTATION__set_token_time(state: any, secs: number) {
    state.tokenExpires = secs
  }
  @Action('login') portalLogin!: (user?: { email: string; password: string }) => ReturnType<typeof Store.prototype.ACTION__login>
  async ACTION__login({ commit, dispatch, state }: ActionContext<any, any>, user: { email: string; password: string }) {

    const { data } = await this.pp.login({
      loginCreds: user
    }, {
      headers: {
        Prefer: `${state.mock.example},${state.mock.dynamic}`
      }
    });
    const { token, expires } = data;

    commit('set_token', token);
    await dispatch('getProfile');
    await dispatch('autoRefreshToken', expires);
  };

  @Action('refreshToken') refreshToken!: () => ReturnType<typeof Store.prototype.ACTION__refreshToken>
  async ACTION__refreshToken({ commit, dispatch, state }: ActionContext<any, any>) {
    const { data } = await this.sv.refreshToken().catch(() => {
      throw "ERROR"
    });
    const { token, expires } = data;
    commit('set_token', token);
    await this.getProfile(true)
    await dispatch('autoRefreshToken', expires);
  }

  @Action('registerSimplyVideoAdmin') registerSimplyVideoAdmin!: (
    deets: {
      form: TForm<Parameters<typeof ProvisioningPortal.prototype.registerSvAdmin>[0]['setSVAdmin']>,
      token: string
    }
  ) => Promise<void>
  async ACTION__registerSimplyVideoAdmin(
    { state, commit, dispatch }: ActionContext<any, any>,
    deets: {
      form: TForm<Parameters<typeof ProvisioningPortal.prototype.registerSvAdmin>[0]['setSVAdmin']>,
      token: string
    }) {

    return this.pp.registerSvAdmin({
      setSVAdmin: {
        last_name: deets.form.last_name.value,
        name: deets.form.name.value,
        password: deets.form.password.value,
        password_confirmation: deets.form.password_confirmation.value,
        email: deets.form.email.value,
      },
      token: deets.token
    }).catch(({ response }) => {
      throw response.data
    })
  }
  /**
   *
   * @param {Number} [time] - Time in minutes. When given, this restarts the timer if present
   * @memberof Store
   */
  @Action('autoRefreshToken') autoRefresh!: (time: number) => void
  ACTION__autoRefreshToken({ state, commit, dispatch }: ActionContext<any, any>, time?: number) {
    const now = Date.now() / 1000 // milliseconds to seconds

    if (time) {
      time = (time * 60) + now // minutes to seconds, the time when the token should expire
      const refresh = time - now // token will refresh in this amount of seconds

      setTimeout(() => dispatch('refreshToken'), refresh * 1000 - 10000)
      commit('set_token_time', time)
    }
    else {
      const refresh = state.tokenExpires - now
      if (!state.tokenExpires || state.tokenExpires <= now ) return commit('flush_auth')
      setTimeout(() => dispatch('refreshToken'), refresh * 1000 - 10000)
    }

    // start auto refresh token timer
  }
  @Action('getProfile') getProfile!: (force?: boolean) => ReturnType<typeof Store.prototype.ACTION__getProfile>
  async ACTION__getProfile({ commit, state }: ActionContext<any, any>, force?: boolean) {

    commit('loading', true)
    if (force || !state.account.role) {
      const res = await this.pp.getProfile({
        headers: {
          Prefer: `${state.mock.example},${state.mock.dynamic}`
        }
      })
      if (res) {
        commit('set_account', res.data);
        commit('loading', false)
        return res.data
      }
    }
    else {
      commit('loading', false)
      return state.account
    }
  }

  @Action('updateProfile') updateProfile!: (args: Parameters<typeof ProvisioningPortal.prototype.updateProfile>[0]) => ReturnType<typeof Store.prototype.ACTION__updateProfile>
  async ACTION__updateProfile({ commit }: ActionContext<any, any>, args: Parameters<typeof ProvisioningPortal.prototype.updateProfile>[0]) {
    this.pp.updateProfile({
      ...args
    })
  }

  @Action('logout') public logout!: (user?: { email: string; password: string }) => ReturnType<typeof Store.prototype.ACTION__login>
  async ACTION__logout({ commit }: ActionContext<any, any>, user: { email: string; password: string }) {
    commit('flush_auth');
  };

  @Action('validateApplicationEmail') public validateApplicationEmail!: (email: string) => ReturnType<typeof Store.prototype.ACTION__validateApplicationEmail>
  async ACTION__validateApplicationEmail({ commit }: ActionContext<any, any>, email: string) {
    return this.pp.distributorApplicationsValidateEmail({
      distirbutorApplicationValidateToken: {
        email
      }
    }).catch(({ response }) => {
      commit('flag_oopsy', response.data)
      throw response.data
    })
  }

  @Action('applyForDistributorAccount') public applyForDistributorAccount!: (profile: TForm<Parameters<typeof ProvisioningPortal.prototype.submitGuestDistributorApplication>[0]['distributorApplicationSubmit']>) => ReturnType<typeof Store.prototype.ACTION__applyForDistributorAccount>
  async ACTION__applyForDistributorAccount({ commit }: ActionContext<any, any>, args: TForm<Parameters<typeof ProvisioningPortal.prototype.submitGuestDistributorApplication>[0]['distributorApplicationSubmit']>) {

    return this.pp.submitGuestDistributorApplication({
      distributorApplicationSubmit: {
        address_line_1: args.address_line_1.value,
        city: args.city.value,
        company_name: args.company_name.value,
        country_id: args.country_id.value,
        email: args.email.value,
        lead_contact: args.lead_contact.value,
        phone: args.phone.value,
        postcode: args.postcode.value,
        state: args.state.value,
        title: args.title.value,
        address_line_2: args.address_line_2?.value,
        company_registration_number: args.company_registration_number?.value,
        website: args.website?.value,
        has_manual_invoices: args.has_manual_invoices?.value
      }
    }).then(({ data }) => {
      return data
    }).catch(({ response }) => {
      throw response.data;
    })

  }

  @Action('resetPassword') public resetPassword!: (body: { token: string; password: string; password_confirmation: string }) => ReturnType<typeof Store.prototype.ACTION__resetPassword>
  async ACTION__resetPassword({ commit }: ActionContext<any, any>, body: { token: string; password: string; password_confirmation: string }) {
    const { token } = body

    const res = await this.sv.resetPassword(token, body).catch(({ response }) => {
      commit('flag_oopsy', response.data)
      return response.data
    })

    return res.data
  }

  @Action('forgotPassword') public forgotPassword!: (email: string) => ReturnType<typeof Store.prototype.ACTION__forgotPassword>
  async ACTION__forgotPassword({ commit }: ActionContext<any, any>, email: string) {
    await this.sv.forgotPassword({ email }).catch((err) => {
      commit('flag_oopsy', err)
    })
  }

  /**
     * Add a list of emails to receive an email to setup their SV Admin Account
     * @param {string[]} list - List of emails to add
   */
  @Action('inviteSvAdmin') public inviteSvAdmin!: (list: string[]) => ReturnType<typeof Store.prototype.ACTION__inviteSvAdmin>
  async ACTION__inviteSvAdmin({ commit }: ActionContext<any, any>, list: string[]) {


    const arr = [...(await this.pp.getSvAdmins()).data.values()]
    let accountToDelete: Promise<any>[] = []
    list.forEach((eml) => {
      const found = arr.find(e => e.email == eml)
      if (found) {
        accountToDelete.push(this.pp.deleteSvAdmin({
          id: found.id.toString()
        }).catch(() => {
          // silently fail
        }))
      }
    })

    await Promise.all(accountToDelete) // make sure all old inivtations have been deleted before re-sending

    return this.pp.svAdminInviteSvAdmin({ inviteSVAdmin: { emails: list } }).catch(({ response }) => {
      commit('flag_oopsy', response.data)

      throw response.data
    })
  }

  @Action('getSvAdmins') public getSvAdmins!: () => ReturnType<typeof Store.prototype.ACTION__getSvAdmins>
  async ACTION__getSvAdmins({ commit }: ActionContext<any, any>) {

    // commit('loading', true)
    return this.pp.getSvAdmins().then(({ data }) => {
      commit('loading', false)
      return data
    }).catch(({ response }) => {
      commit('loading', false)
      commit('flag_oopsy', response.data)
      throw response.data
    })
  }

  @Action('getListOfLicensesAssociatedWithAnAccount') getListOfLicensesAssociatedWithAnAccount!: (id: number) => ReturnType<typeof Store.prototype.ACTION__getListOfLicensesAssociatedWithAnAccount>
  async ACTION__getListOfLicensesAssociatedWithAnAccount({ commit, getters }: ActionContext<any, any>, id: number) {
    switch (getters.typeOfAccount) {
      case Types.Role.SvAdmin:
        return (await this.pp.svAdminDistributor({
          id
        })).data.licenses
      case Types.Role.Distributor:
        return (await this.pp.getDistributorCompaniesCompanyId({
          companyId: id.toString()
        })).data.licenses
    }
  }

  @Action('getDistributor') public getDistributor!: (id: number) => ReturnType<typeof Store.prototype.ACTION__getDistributor>
  async ACTION__getDistributor({ commit }: ActionContext<any, any>, id: number) {

    commit('loading', true)
    const res = await this.pp.svAdminDistributor({
      id
    })
      .then(({ data }) => {
        return data
      })
      .catch(({ response }) => {
        commit('loading', false)
        commit('flag_oopsy', response.data)
        throw response.data
      })

    commit('loading', false)
    return res
  }

  @Action('editDistributorAccount') editDistributorAccount!: (account: { id: number; accountManagerId: number }) => Promise<void>
  async ACTION__editDistributorAccount({ commit }: ActionContext<any, any>, account: { id: number; accountManagerId: number }) {
    return this.pp.editDistributor({
      updateDistributor: {
        account_manager_id: account.accountManagerId.toString(),
      },
      id: account.id
    }).catch(({ response }) => {
      commit('flag_oopsy', response.data)
      return response
    })
  }

  @Action('getCompany') public getCompany!: (id: number) => ReturnType<typeof Store.prototype.ACTION__getCompany>
  async ACTION__getCompany({ commit }: ActionContext<any, any>, id: number) {
    return (await this.pp.getDistributorCompaniesCompanyId({
      companyId: id.toString()
    })).data
  }

  @Action('getListOfDistributorApplications') public getListOfDistributorApplications!: () => ReturnType<typeof Store.prototype.ACTION__getListOfDistributorApplications>
  async ACTION__getListOfDistributorApplications({ commit }: ActionContext<any, any>) {

    commit('loading', true)
    const res = await this.pp.svAdminDistributorApplicationsList().catch(({ response }) => {
      commit('flag_oopsy', response.data)
      return response
    })
    if (res) {
      commit('loading', false)
      return res.data
    }
  }

  @Action('getDistributorApplication') public getDistributorApplication!: (id: number) => ReturnType<typeof Store.prototype.ACTION__getDistributorApplication>
  async ACTION__getDistributorApplication({ getters, commit }: ActionContext<any, any>, id: number) {

    commit('loading', true)
    let res = null

    if (getters.typeOfAccount === Types.Role.SvAdmin) {
      return this.pp.svAdminDistributorApplication({
        id
      }).then(({ data }) => {
        commit('loading', false)
        return data
      }).catch(({ response }) => {
        commit('flag_oopsy', response.data)
        throw response.data
      })
    }
    else {
      commit('loading', false)
      throw new Error('No Access')
    }
  }

  @Action('approveDistributorApplication') public approveDistributorApplication!: (
    deets: {
      id: number,
      form: Parameters<typeof ProvisioningPortal.prototype.svAdminApproveDistributorApplication>[0]['approveDistributorApplication']
    }
  ) => ReturnType<typeof Store.prototype.ACTION__approveDistributorApplication>
  async ACTION__approveDistributorApplication({ commit }: ActionContext<any, any>,
    deets: {
      id: number,
      form: Parameters<typeof ProvisioningPortal.prototype.svAdminApproveDistributorApplication>[0]['approveDistributorApplication']
    }
  ) {
    const { data }: any = await this.pp.svAdminApproveDistributorApplication({
      id: deets.id,
      approveDistributorApplication: {
        ...deets.form
      }
    }).catch(({ response }) => {
      commit('flag_oopsy', response.data)
      return response
    })

    return data
  }

  @Action('denyDistributorApplication') public denyDistributorApplication!: (id: number) => ReturnType<typeof Store.prototype.ACTION__denyDistributorApplication>
  async ACTION__denyDistributorApplication({ commit }: ActionContext<any, any>, id: number) {

    commit('loading', true)
    const res = await this.pp.svAdminDenyDistributorApplication({ id }).catch((err) => {
      commit('flag_oopsy', err)
    });

    if (res) {
      commit('loading', false)
      return res.data;
    }
  }

  @Action('getListOfDistributors') public getListOfDistributors!: () => ReturnType<typeof Store.prototype.ACTION__getListOfDistributors>
  async ACTION__getListOfDistributors({ commit, getters, state }: ActionContext<any, any>) {

    commit('loading', true)
    if (getters.typeOfAccount === Types.Role.SvAdmin) {

      return (await this.pp.svAdminsDistributorsList().then(({ data }) => {
        commit('loading', false)
        return data
      }))
    }
    else {
      commit('loading', false)
      throw new Error('No Access')
    }
  }

  @Action('getListOfResellers') public getListOfResellers!: (id?: string) => ReturnType<typeof Store.prototype.ACTION__getListOfResellers>
  async ACTION__getListOfResellers({ commit, getters, state }: ActionContext<any, any>, id?: string) {

    commit('loading', true)
    if (getters.typeOfAccount === Types.Role.SvAdmin) {
      if(id) {
        return (await this.pp.getSvAdminsResellers({distributorId: id}).then(({ data }) => {
          commit('loading', false)
          return data
        }))
      } else {
        return (await this.pp.getSvAdminsResellers().then(({ data }) => {
          commit('loading', false)
          return data
        }))
      }
    }
    if (getters.typeOfAccount === Types.Role.Distributor) {

      return (await this.pp.getDistributorResellers().then(({ data }) => {
        commit('loading', false)
        return data
      }))
    }
    else {
      commit('loading', false)
      throw new Error('No Access')
    }
  }

  @Action('getListOfCustomers') public getListOfCustomers!: (id?: string) => ReturnType<typeof Store.prototype.ACTION__getListOfCustomers>
  async ACTION__getListOfCustomers({ commit, getters, state }: ActionContext<any, any>, id?: string) {

    commit('loading', true)
    let res = null
    if (getters.typeOfAccount === Types.Role.SvAdmin) {
      if(id) {
        return (await this.pp.getSvAdminsCompanies({resellerId: id}).then(({ data }) => {
          commit('loading', false)
          return data
        }))
      } else {
        return (await this.pp.getSvAdminsCompanies().then(({ data }) => {
          commit('loading', false)
          return data
        }))
      }
    }
    if (getters.typeOfAccount === Types.Role.Distributor) {
      res = await this.pp.getDistributorCompanies().catch((err) => {
        commit('flag_oopsy', err)
      });
    } else if (getters.typeOfAccount === Types.Role.Reseller) {
      res = await this.pp.getResellerCompanies().catch((err) => {
        commit('flag_oopsy', err)
      });
    }
    else {
      commit('loading', false)
      throw new Error('No Access')
    }

    if (res) {
      commit('loading', false)
      return res.data;
    }
  }

  @Action('getAccount') public getAccount!: (id: number) => ReturnType<typeof Store.prototype.ACTION__getAccount>
  async ACTION__getAccount({ commit }: ActionContext<any, any>, id: number) {

    commit('loading', true)

    const res = await this.pp.getAccount({ id }).catch((err) => {
      commit('flag_oopsy', err)
      commit('loading', false)
    });

    if (res) {
      commit('loading', false)
      return res.data;
    }
  }
  @Action('addLicensesToDistributorAccount') public addLicensesToDistributorAccount!: (req: Parameters<typeof ProvisioningPortal.prototype.editDistributorLicenses>[0]) => ReturnType<typeof Store.prototype.ACTION__addLicensesToDistributorAccount>
  async ACTION__addLicensesToDistributorAccount({ commit }: ActionContext<any, any>, arg: Parameters<typeof ProvisioningPortal.prototype.editDistributorLicenses>[0]) {

    commit('sending', true)
    let res = null

    res = await this.pp.editDistributorLicenses(
      arg
    )
      .catch((err) => {
        commit('sending', false)
        commit('flag_oopsy', err)
        throw err.response.data
      });

    if (res) {
      commit('sending', false)
      return res.data;
    }
  }

  @Action('addLicensesToCompanyAccount') public addLicensesToCompanyAccount!: (req: Parameters<typeof ProvisioningPortal.prototype.assingLicensesToCompany>[0]) => ReturnType<typeof Store.prototype.ACTION__addLicensesToCompanyAccount>
  async ACTION__addLicensesToCompanyAccount({ commit }: ActionContext<any, any>, arg: Parameters<typeof ProvisioningPortal.prototype.assingLicensesToCompany>[0]) {

    commit('sending', true)
    let res = null

    res = await this.pp.assingLicensesToCompany(
      arg
    )
      .catch((err) => {
        commit('sending', false)
        commit('flag_oopsy', err)
        throw err.response.data
      });

    if (res) {
      commit('sending', false)
      return res.data;
    }
  }

  /**
   * Get a list of licenses that this user has pooled
   */
  @Action('getListOfAssingableLicenses') public getListOfAssingableLicenses!: () => ReturnType<typeof Store.prototype.ACTION__getListOfAssingableLicenses>
  async ACTION__getListOfAssingableLicenses({ commit, getters }: ActionContext<any, any>) {

    commit('loading', true)
    let res = null

    if (getters.typeOfAccount === Types.Role.SvAdmin) {
      const { billing_plans, addons } = (await this.pp.getSvAdminLicenses()).data
      res = billing_plans.concat(addons)

    }
    else if (getters.typeOfAccount === Types.Role.Distributor) {
      res = (await this.pp.getDistributorLicenses()).data.licenses
    }
    else {
      commit('loading', false)
      throw new Error('No Access')
    }

    if (res) {
      commit('loading', false)
      return res;
    }
  }

  /**
   * Get a list of currencies
   * 
   * If the state property is currently empty, this will call the endpoint.
   * If the state prop is already populated, it won't call the endpoint and the state won't change.
   * 
   * @param {Boolean} force - forces the state to be overriden
   * @memberof Store
   */
  @Action('getCurrencies') public getCurrencies!: (force?: boolean) => ReturnType<typeof Store.prototype.ACTION__getCurrencies>
  async ACTION__getCurrencies({ commit, getters, state }: ActionContext<any, any>, force: boolean) {

    if (force || !state.currencies.length) {
      const { data } = await this.sv.getCurrencies()
      commit('set_currencies', data)
      return data
    }
  }

  /**
   * Get a list of countries
   * 
   * If the state property is currently empty, this will call the endpoint.
   * If the state prop is already populated, it won't call the endpoint and the state won't change.
   * 
   * @param {Boolean} force - forces the state to be overriden
   * @memberof Store
   */
  @Action('getCountries') public getCountries!: (force?: boolean) => ReturnType<typeof Store.prototype.ACTION__getCountries>
  async ACTION__getCountries({ commit, getters, state }: ActionContext<any, any>, force: boolean) {

    if (force || !state.countries.length) {
      const { data } = await this.sv.getCountries()
      commit('set_countries', data)
      return data
    }
  }


  /** DISTRIBUTOR ENDPOINTS */

  /**
     * Add a list of emails to receive an email to setup their SV Reseller Account
     * @param {string[]} list - List of emails to add
   */
  @Action('inviteResellers') public inviteResellers!: (list: Parameters<typeof ProvisioningPortal.prototype.inviteResellers>[0]) => ReturnType<typeof Store.prototype.ACTION__inviteResellers>
  async ACTION__inviteResellers({ commit, getters }: ActionContext<any, any>, data: Parameters<typeof ProvisioningPortal.prototype.inviteResellers>[0]) {

    const arr = [...(await this.pp.getDistributorResellers()).data.values()]
    let accountToDelete: Promise<any>[] = []
    data.listOfEmails.emails.forEach((eml) => {
      const found = arr.find(e => e.email == eml)
      if (found) {
        accountToDelete.push(this.pp.deleteDistributorResellersId({
          id: found.id.toString()
        }).catch(() => {
          // silently fail
        }))
      }
    })

    await Promise.all(accountToDelete) // make sure all old inivtations have been deleted before re-sending

    switch (getters.typeOfAccount) {
      case Types.Role.Distributor:
        return (await this.pp.inviteResellers(
          { ...data }
        )).data
      default:
        throw new Error('No Access')
    }
  }

  @Action('getDistributorResellersId') public getDistributorResellersId!: (id: string) => ReturnType<typeof Store.prototype.ACTION__getDistributorResellersId>
  async ACTION__getDistributorResellersId({ commit }: ActionContext<any, any>, id: string) {

    const res = await this.pp.getDistributorResellersId({
      id
    }).catch(({ response }) => {
      commit('flag_oopsy', response.data)
      return response
    })
    return res.data
  }

  // Customer Endpoints

  @Action('createCustomerAccount') createCustomerAccount!: (args: Parameters<typeof ProvisioningPortal.prototype.postResellerCompanies>[0]) => ReturnType<typeof Store.prototype.ACTION__createCustomerAccount>
  async ACTION__createCustomerAccount({ commit, getters, dispatch }: ActionContext<any, any>, args: Parameters<typeof ProvisioningPortal.prototype.postResellerCompanies>[0]) {



    commit('sending', true)
    let res = null

    if (getters.typeOfAccount === Types.Role.Reseller) {
      res = await this.pp.postResellerCompanies({
        ...args
      }).catch((err) => {
        commit('sending', false)
        commit('flag_oopsy', err)
        throw err.response.data
      });
    }
    else {
      commit('sending', false)
      throw new Error('No Access')
    }

    if (res) {
      commit('sending', false)
      return res;
    }
  }

  @Action('updateCustomerAccount') updateCustomerAccount!: (args: Parameters<typeof ProvisioningPortal.prototype.postResellerCompaniesCompanyId>[0]) => ReturnType<typeof Store.prototype.ACTION__updateCustomerAccount>
  async ACTION__updateCustomerAccount({ commit, getters, dispatch }: ActionContext<any, any>, args: Parameters<typeof ProvisioningPortal.prototype.postResellerCompaniesCompanyId>[0]) {

    // 

    commit('sending', true)
    let res = null

    if (getters.typeOfAccount === Types.Role.Reseller) {
      res = await this.pp.postResellerCompaniesCompanyId({
        ...args
      }).catch((err: any) => {
        commit('sending', false)
        commit('flag_oopsy', err)
        throw err.response.data
      });
    }
    else {
      commit('sending', false)
      throw new Error('No Access')
    }

    if (res) {
      commit('sending', false)
      return res;
    }
  }

  @Action('deleteBrandingImage') public deleteBrandingImage!: (args: Parameters<typeof ProvisioningPortal.prototype.deleteResellerBrandingImageImageCompanyId>[0]) => ReturnType<typeof Store.prototype.ACTION__deleteBrandingImage>
  async ACTION__deleteBrandingImage({ commit, getters }: ActionContext<any, any>, args: Parameters<typeof ProvisioningPortal.prototype.deleteResellerBrandingImageImageCompanyId>[0]) {

    commit('loading', true)
    let res = null

    if (getters.typeOfAccount === Types.Role.Reseller) {
      res = await this.pp.deleteResellerBrandingImageImageCompanyId({
        ...args
      }).catch((err) => {
        commit('flag_oopsy', err)
      });
    }
    else {
      commit('loading', false)
      throw new Error('No Access')
    }

    if (res) {
      commit('loading', false)
      return res.data;
    }
  }

  @Action('restoreDefaultBranding') public restoreDefaultBranding!: (args: Parameters<typeof ProvisioningPortal.prototype.postResellerRestoreDefaultBrandingCompanyId>[0]) => ReturnType<typeof Store.prototype.ACTION__restoreDefaultBranding>
  async ACTION__restoreDefaultBranding({ commit, getters }: ActionContext<any, any>, args: Parameters<typeof ProvisioningPortal.prototype.postResellerRestoreDefaultBrandingCompanyId>[0]) {

    commit('loading', true)
    let res = null

    if (getters.typeOfAccount === Types.Role.Reseller) {
      res = await this.pp.postResellerRestoreDefaultBrandingCompanyId({
        ...args
      }).catch((err) => {
        commit('flag_oopsy', err)
      });
    }
    else {
      commit('loading', false)
      throw new Error('No Access')
    }

    if (res) {
      commit('loading', false)
      return res.data;
    }
  }

  @Action('getCompanyAccount') public getCompanyAccount!: (companyId: string) => ReturnType<typeof Store.prototype.ACTION__getCompanyAccount>
  async ACTION__getCompanyAccount({ commit, getters }: ActionContext<any, any>, companyId: string) {

    commit('loading', true)
    let res = null
    if (getters.typeOfAccount === Types.Role.SvAdmin) {
      res = (await this.pp.getSvAdminsCompany({
        companyId
      }))
    } else
    if (getters.typeOfAccount === Types.Role.Distributor) {
      res = (await this.pp.getDistributorCompaniesCompanyId({
        companyId
      }))
    } else if (getters.typeOfAccount === Types.Role.Reseller) {
      res = (await this.pp.getResellerCompaniesCompanyId({
        companyId
      }))
    }
    else {
      commit('loading', false)
      throw new Error('No Access')
    }

    if (res) {
      commit('loading', false)
      return res.data;
    }
  }

  @Action('getBillingDetails') public getBillingDetails!: () => ReturnType<typeof Store.prototype.ACTION__getBillingDetails>
  async ACTION__getBillingDetails({ commit, getters }: ActionContext<any, any>) {

    commit('loading', true)
    let res = null

    if (getters.typeOfAccount === Types.Role.Distributor) {
      res = (await this.pp.getDistributorBillingDetails())
    }
    else {
      commit('loading', false)
      throw new Error('No Access')
    }

    if (res) {
      commit('loading', false)
      return res.data;
    }
  }

  @Action('updateBillingDetails') public updateBillingDetails!: (args: Parameters<typeof ProvisioningPortal.prototype.updateDistributorBillingDetails>[0]) => ReturnType<typeof Store.prototype.ACTION__updateBillingDetails>
  async ACTION__updateBillingDetails({ commit, getters }: ActionContext<any, any>, args: Parameters<typeof ProvisioningPortal.prototype.updateDistributorBillingDetails>[0]) {

    commit('sending', true)
    let res = null

    if (getters.typeOfAccount === Types.Role.Distributor) {
      res = await this.pp.updateDistributorBillingDetails(args).catch((err) => {
        commit('sending', false)
        commit('flag_oopsy', err)
        throw err.response.data
      });
    }
    else {
      commit('sending', false)
      throw new Error('No Access')
    }

    if (res) {
      commit('sending', false)
      return res.data;
    }
  }

  @Action('updateEmail') public updateEmail!: (args: Parameters<typeof SV.prototype.updateEmail>[0]) => ReturnType<typeof Store.prototype.ACTION__updateEmail>
  async ACTION__updateEmail({ commit }: ActionContext<any, any>, args: Parameters<typeof SV.prototype.updateEmail>[0]) {
    return (await this.sv.updateEmail(args)).data
  }

  /**
   * Resend Customer Email Invite
   * 
   * @param {userId}
   * @memberof Store
   */
  @Action('resendCustomerUserInvite') public resendCustomerUserInvite!: (userId: any) => ReturnType<typeof Store.prototype.ACTION__resendCustomerUserInvite>
  async ACTION__resendCustomerUserInvite({ commit, getters }: ActionContext<any, any>, userId: any) {

    switch (getters.typeOfAccount) {
      case Types.Role.Reseller:
        return (await this.pp.postResellerUserSetupReminderUserId(
          userId
        ).catch((err) => {
          commit('flag_oopsy', err)
        })
        )
      default:
        throw new Error('No Access')
    }
  }

  /**
   * Delete Customer User
   * 
   * @param {userId}
   * @memberof Store
   */
  @Action('deleteCustomerUser') public deleteCustomerUser!: (userId: any) => ReturnType<typeof Store.prototype.ACTION__deleteCustomerUser>
  async ACTION__deleteCustomerUser({ commit, getters }: ActionContext<any, any>, userId: any) {

    switch (getters.typeOfAccount) {
      case Types.Role.Reseller:
        return (await this.pp.deleteResellerDeleteUserUserId(
          userId
        ).catch((err) => {
          commit('flag_oopsy', err)
        })
        )
      default:
        throw new Error('No Access')
    }
  }

  /**
   * Invite Customer User
   * 
   * @param {companyId}
   * @memberof Store
   */
  @Action('inviteCustomerUser') public inviteCustomerUser!: (args: Parameters<typeof ProvisioningPortal.prototype.postResellerInviteUserCompanyId>[0]) => ReturnType<typeof Store.prototype.ACTION__inviteCustomerUser>
  async ACTION__inviteCustomerUser({ commit, getters }: ActionContext<any, any>, args: Parameters<typeof ProvisioningPortal.prototype.postResellerInviteUserCompanyId>[0]) {



    commit('sending', true)
    let res = null

    if (getters.typeOfAccount === Types.Role.Reseller) {
      res = await this.pp.postResellerInviteUserCompanyId({
        ...args
      }).catch((err: any) => {
        commit('sending', false)
        commit('flag_oopsy', err)
        throw err.response.data
      });
    }
    else {
      commit('sending', false)
      throw new Error('No Access')
    }

    if (res) {
      commit('sending', false)
      return res;
    }
  }

  @Action('getUpcomingInvoice') public getUpcomingInvoice!: () => ReturnType<typeof Store.prototype.ACTION__getUpcomingInvoice>
  async ACTION__getUpcomingInvoice({ commit, getters }: ActionContext<any, any>) {
    commit('loading', true)
    switch (getters.typeOfAccount) {
      case Types.Role.Distributor:
        commit('loading', false)
        return (await this.pp.getDistributorUpcomingInvoice()).data
        break;

      default:
        commit('loading', false)
        throw new Error('No Access')
        break;
    }
  }

  @Action('getBillingHistory') public getBillingHistory!: () => ReturnType<typeof Store.prototype.ACTION__getBillingHistory>
  async ACTION__getBillingHistory({ commit, getters }: ActionContext<any, any>) {
    commit('loading', true)
    let res = null

    if (getters.typeOfAccount === Types.Role.Distributor) {
      res = (await this.pp.getDistributorInvoices())
    }
    else {
      commit('loading', false)
      throw new Error('No Access')
    }

    if (res) {
      commit('loading', false)
      return res.data;
    }
  }

  @Action('validateForgotPasswordCode') public validateForgotPasswordCode!: (code: string) => Promise<void>
  async ACTION__validateForgotPasswordCode({ commit, getters }: ActionContext<any, any>, code: string) {
    return (await this.sv.validateForgotPasswordCode(code)).data
  }

  @Action('distributorRegistrationValidateToken') distributorRegistrationValidateToken!: (code: string) => ReturnType<typeof Store.prototype.ACTION__distributorRegistrationValidateToken>
  async ACTION__distributorRegistrationValidateToken({ commit, getters }: ActionContext<any, any>, code: string) {
    return (await this.pp.distributorSetupValidateToken({
      token: code
    })).data

  }

  @Action('distributorRegistrationStepOne') distributorRegistrationStepOne!: (deets: {
    profile: TForm<Parameters<typeof ProvisioningPortal.prototype.distributorSetupStepOne>[0]['businessProfile']>,
    code: string
  }) => ReturnType<typeof Store.prototype.ACTION__distributorRegistrationStepOne>
  async ACTION__distributorRegistrationStepOne({ commit, getters }: ActionContext<any, any>, deets: {
    profile: TForm<Parameters<typeof ProvisioningPortal.prototype.distributorSetupStepOne>[0]['businessProfile']>,
    code: string
  }) {
    return this.pp.distributorSetupStepOne({
      token: deets.code,
      businessProfile: {
        address_line_1: deets.profile.address_line_1.value,
        business_name: deets.profile.business_name.value,
        country: deets.profile.country.value,
        county_state: deets.profile.county_state.value,
        email: deets.profile.email.value,
        lead_contact: deets.profile.lead_contact.value,
        postcode: deets.profile.postcode.value,
        town: deets.profile.town.value,
        address_line_2: deets.profile.address_line_2?.value,
        web_address: deets.profile.web_address?.value
      }
    }).catch(({ response }: any) => {
      throw response.data
    })
  }

  @Action('distributorRegistrationStepTwo') distributorRegistrationStepTwo!: (deets: {
    details: TForm<Parameters<typeof ProvisioningPortal.prototype.distributorSetupStepTwo>[0]['setBillingDetails']>,
    code: string
  }) => ReturnType<typeof Store.prototype.ACTION__distributorRegistrationStepTwo>
  async ACTION__distributorRegistrationStepTwo(
    { commit, getters }: ActionContext<any, any>,
    deets: {
      details: TForm<Parameters<typeof ProvisioningPortal.prototype.distributorSetupStepTwo>[0]['setBillingDetails']>,
      code: string
    }
  ) {
    return this.pp.distributorSetupStepTwo({
      token: deets.code,
      setBillingDetails: {
        address_line_1: deets.details.address_line_1.value,
        country_id: deets.details.country_id.value,
        county_state: deets.details.county_state.value,
        email: deets.details.email.value,
        postcode: deets.details.postcode.value,
        town: deets.details.town?.value,
        address_line_2: deets.details.address_line_2?.value,
        full_name: deets.details?.full_name.value,
        phone: deets.details?.phone.value,
        additional_email: deets.details.additional_email?.value,
        vat_number: deets.details?.vat_number?.value,
      }
    }).catch(({ response }) => {
      throw response.data
    })
  }

  @Action('resellerRegistrationCodeValidation') public resellerRegistrationCodeValidation!: (code: string) => ReturnType<typeof Store.prototype.ACTION__resellerRegistrationCodeValidation>
  async ACTION__resellerRegistrationCodeValidation({ commit, getters }: ActionContext<any, any>, code: string) {
    return (await this.pp.validateResellerSetupToken({
      token: code
    })).data
  }

  @Action('resellerRegistration') public resellerRegistration!: (
    info: TForm<Parameters<typeof ProvisioningPortal.prototype.setupResellerCredentials>[0]['setCredentials']> &
      TForm<Parameters<typeof ProvisioningPortal.prototype.setupResellerBusinessProfile>[0]['businessProfile']> &
    { code: string }
  ) => ReturnType<typeof Store.prototype.ACTION__resellerRegistration>
  async ACTION__resellerRegistration({ commit, getters }: ActionContext<any, any>,
    info: TForm<Parameters<typeof ProvisioningPortal.prototype.setupResellerCredentials>[0]['setCredentials']> &
      TForm<Parameters<typeof ProvisioningPortal.prototype.setupResellerBusinessProfile>[0]['businessProfile']> &
    { code: string }
  ) {
    const res = (await this.pp.setupResellerCredentials({
      setCredentials: {
        email: info.email.value,
        password: info.email.value,
        password_confirmation: info.password_confirmation.value
      },
      token: info.code
    })).data

    await this.pp.setupResellerBusinessProfile({
      businessProfile: {
        address_line_1: info.address_line_1.value,
        business_name: info.business_name.value,
        country: info.country.value,
        county_state: info.county_state.value,
        email: info.email.value,
        lead_contact: info.lead_contact.value,
        postcode: info.postcode.value,
        town: info.town.value,
        address_line_2: info.address_line_2?.value,
        web_address: info.web_address?.value,
      },
      token: info.code
    })
    return res
  }

  @Action('resellerRegistrationStepTwo') public resellerRegistrationStepTwo!: (
    info: {
      creds: TForm<Parameters<typeof ProvisioningPortal.prototype.setupResellerCredentials>[0]['setCredentials']>,
      code: string
    }
  ) => ReturnType<typeof Store.prototype.ACTION__resellerRegistrationStepTwo>
  async ACTION__resellerRegistrationStepTwo({ commit, getters }: ActionContext<any, any>,
    info: {
      creds: TForm<Parameters<typeof ProvisioningPortal.prototype.setupResellerCredentials>[0]['setCredentials']>,
      code: string
    }
  ) {
    return this.pp.setupResellerCredentials({
      setCredentials: {
        email: info.creds.email.value,
        password: info.creds.password.value,
        password_confirmation: info.creds.password_confirmation.value
      },
      token: info.code
    }).catch(({ response }) => {
      throw response.data
    })
  }

  @Action('distributorRegistrationStepThree') distributorRegistrationStepThree!: (deets: {
    creds: TForm<Parameters<typeof ProvisioningPortal.prototype.distributorSetupStepThree>[0]['setCredentials']>,
    code: string
  }) => ReturnType<typeof Store.prototype.ACTION__distributorRegistrationStepThree>
  async ACTION__distributorRegistrationStepThree(
    { commit, getters }: ActionContext<any, any>,
    deets: {
      creds: TForm<Parameters<typeof ProvisioningPortal.prototype.distributorSetupStepThree>[0]['setCredentials']>,
      code: string
    }
  ) {
    return this.pp.distributorSetupStepThree({
      setCredentials: {
        email: deets.creds.email.value,
        password: deets.creds.password.value,
        password_confirmation: deets.creds.password_confirmation.value,
      },
      token: deets.code
    }).catch(({ response }) => {
      throw response.data
    })
  }
  @Action('resellerRegistrationStepOne') public resellerRegistrationStepOne!: (
    info: {
      profile: TForm<Parameters<typeof ProvisioningPortal.prototype.setupResellerBusinessProfile>[0]['businessProfile']>,
      code: string
    }
  ) => ReturnType<typeof Store.prototype.ACTION__resellerRegistrationStepOne>
  async ACTION__resellerRegistrationStepOne({ commit, getters }: ActionContext<any, any>,
    info: {
      profile: TForm<Parameters<typeof ProvisioningPortal.prototype.setupResellerBusinessProfile>[0]['businessProfile']>,
      code: string
    }
  ) {
    return this.pp.setupResellerBusinessProfile({
      businessProfile: {
        address_line_1: info.profile.address_line_1.value,
        business_name: info.profile.business_name.value,
        country: info.profile.country.value,
        county_state: info.profile.county_state.value,
        email: info.profile.email.value,
        lead_contact: info.profile.lead_contact.value,
        postcode: info.profile.postcode.value,
        town: info.profile.town.value,
        address_line_2: info.profile.address_line_2?.value,
        web_address: info.profile.web_address?.value,
      },
      token: info.code
    }).catch(({ response }) => {
      throw response.data
    })
  }

  @Action('distributorRegistrationSetupPayment') distributorRegistrationSetupPayment!: (
    deets: {
      code: string,
      el: string
    }
  ) => ReturnType<typeof Store.prototype.ACTION__distributorRegistrationSetupPayment>
  async ACTION__distributorRegistrationSetupPayment(
    { commit, getters }: ActionContext<any, any>,
    deets: {
      code: string,
      el: string // where to mount the stripe elements
    }
  ) {


    const d = (await this.pp.distributorSetupPaymentForm({
      token: deets.code
    })).data
    const stripe = await loadStripe(d.stripe_key);
    if (!stripe) throw "BAD"
    this.stripe = stripe
    let elements = stripe.elements();
    const card = elements.create('card', { hidePostalCode: true });
    card.mount(deets.el);
    return { card, tokens: d }
  }

  @Action('distributorRegistrationStepFour') distributorRegistrationStepFour!: (
    deets: {
      code: string,
      stripe_secret: string,
      card: StripeCardElement
    }
  ) => ReturnType<typeof Store.prototype.ACTION__distributorRegistrationStepFour>
  async ACTION__distributorRegistrationStepFour(
    { commit, getters }: ActionContext<any, any>,
    deets: {
      code: string,
      stripe_secret: string,
      card: StripeCardElement
    }
  ) {

    const pm = (await this.stripe?.confirmCardSetup(
      deets.stripe_secret,
      {
        payment_method: {
          card: deets.card
        }
      }
    ))?.setupIntent?.payment_method

    if (!pm) throw "somethin went wrong cap'n"

    this.pp.distributorSetupStepFour({
      token: deets.code,
      updatePaymentMethod: {
        payment_method: pm
      }
    })
  }
  @Action('getStatistics') public getStatistics!: (force?: boolean) => ReturnType<typeof Store.prototype.ACTION__getStatistics>
  async ACTION__getStatistics({ commit }: ActionContext<any, any>) {

    commit('loading', true)
    let res = null
    res = (await this.pp.getStatistics())

    if (res) {
      commit('loading', false)
      return res.data;
    }
  }

  @Action('cancelLicenseChange') cancelLicenseChange!: (id: number) => ReturnType<typeof Store.prototype.ACTION__cancelLicenseChange>
  async ACTION__cancelLicenseChange({ commit }: ActionContext<any, any>, id: number) {
    return this.pp.postSvAdminDistributorLicensesCancelScheduledPriceUpdateDistributorLicenseId({
      distributorLicenseChangeId: id.toString()
    })
  }

  @Action('getPaymentMethod') getPaymentMethod!: () => ReturnType<typeof Store.prototype.ACTION__getPaymentMethod>
  async ACTION__getPaymentMethod({ getters }: ActionContext<any, any>) {
    switch (getters.typeOfAccount) {
      case Types.Role.Distributor:
        return (await this.pp.getDistributorPaymentMethod()).data
    }
  }

  @Action('initStripe') initStripe!: (el: string) => ReturnType<typeof Store.prototype.ACTION__initStripe>
  async ACTION__initStripe({ getters }: ActionContext<any, any>, el: string) {
    switch (getters.typeOfAccount) {
      case Types.Role.Distributor:
        const d = (await this.pp.getDistributorPaymentForm()).data
        const stripe = await loadStripe(d.stripe_key);
        if (!stripe) throw "BAD"
        this.stripe = stripe
        let elements = stripe.elements();
        const card = elements.create('card', { hidePostalCode: true });
        card.mount(el);
        return { card, tokens: d }
      default:
        throw new Error('No Access')
    }
  }

  @Action('removePaymentMethod') removePaymentMethod!: () => Promise<void>
  async ACTION__removePaymentMethod() {
    return this.pp.deleteDistributorPaymentMethod()
  }

  @Action('updatePaymentMethod') updatePaymentMethod!: (
    deets: {
      stripe_secret: string,
      card: StripeCardElement
    }
  ) => ReturnType<typeof Store.prototype.ACTION__updatePaymentMethod>
  async ACTION__updatePaymentMethod(
    { getters }: ActionContext<any, any>,
    deets: {
      stripe_secret: string,
      card: StripeCardElement
    }
  ) {
    switch (getters.typeOfAccount) {
      case Types.Role.Distributor:
        const pm = (await this.stripe?.confirmCardSetup(
          deets.stripe_secret,
          {
            payment_method: {
              card: deets.card
            }
          }
        ))?.setupIntent?.payment_method

        if (!pm) throw "somethin went wrong cap'n"
        return (await this.pp.patchDistributorPaymentMethod({
          updatePaymentMethod: {
            payment_method: pm
          }
        })).data
    }
  }

  @Action('extendTrialLicence') extendTrialLicence!: (companyId: number) => ReturnType<typeof Store.prototype.ACTION__extendTrialLicence>
  async ACTION__extendTrialLicence({ commit }: ActionContext<any, any>, companyId: number) {
    return this.pp.postDistributorExtendTrialCompanyId({
      companyId: companyId.toString()
    })
  }

  @Action('updateCustomerEmail') updateCustomerEmail!: (args: Parameters<typeof ProvisioningPortal.prototype.updateDistributorCompanyId>[0]) => ReturnType<typeof Store.prototype.ACTION__updateCustomerEmail>
  async ACTION__updateCustomerEmail({ commit }: ActionContext<any, any>, args: Parameters<typeof ProvisioningPortal.prototype.updateDistributorCompanyId>[0]) {
    this.pp.updateDistributorCompanyId({...args})
  }

  @Action('setUserCache') setUserCache!: (args: Parameters<typeof ProvisioningPortal.prototype.setUserCache>[0]) => ReturnType<typeof Store.prototype.ACTION__setUserCache>
  async ACTION__setUserCache({ commit }: ActionContext<any, any>, args: Parameters<typeof ProvisioningPortal.prototype.setUserCache>[0]) {
    return this.pp.setUserCache({...args})
  }
}