







































import Component from 'vue-class-component'
import {Watch} from 'vue-property-decorator'
import { Store } from '@/store/store'
import {Login} from '../router';

// @vuese
// @group Global
// Navbar
@Component({})
export default class Navbar extends Store {

  has_account: boolean = true

  /**
   * Used to toggle the login state
   * @returns {void}
   * @vuese
   */
  async toggleLogin() {
    if (this.isLoggedIn) {
      // Logout
      await this.$store.dispatch('logout');
      this.$router.push(Login);
    } else {
      // Login
      this.$router.push(Login);
    }
  }

  @Watch('account', {deep: true})
  hasAccountName() {
    this.has_account = this.account && this.account.name ? true : false
  }

  logo() {
    return '/assets/img/sv-logo.png';
  }
};
