import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import router from './router';
import store from './store';

import VueMeta from 'vue-meta';
import Toasted from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import '@/assets/css/global.scss'

import capitalise from '../src/components/filters/capitalise'
import titleFormat from '../src/components/filters/titleFormat'
import dateFormat from '../src/components/filters/dateFormat'
import dateInString from '../src/components/filters/dateInString'
import daysRemaining from '../src/components/filters/daysRemaining'
import currencySymbol from '../src/components/filters/currencySymbol'

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import VTooltip from 'v-tooltip'

Vue.use(VTooltip)

import VCalendar from 'v-calendar';

// Use v-calendar & v-date-picker components
Vue.use(VCalendar);

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

import VueBootstrap from 'bootstrap-vue';
Vue.use(VueBootstrap);


switch (process.env.NODE_ENV) {
  case 'production':
  case 'shared':
    Vue.config.silent = true
    Vue.config.devtools = false
    Vue.config.ignoredElements = [
      'debug',
      // Use a `RegExp` to ignore all elements that start with "ion-"
      // 2.5+ only
    ]
    Vue.config.performance = false
    Vue.config.productionTip = false;
    break;

  default:
    Vue.config.silent = false
    Vue.config.devtools = true
    Vue.config.ignoredElements = []
    Vue.config.performance = true
    Vue.config.productionTip = false
    break;
}

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
}),
Vue.use(Toasted);
Vue.use(VueRouter);

// Allows for the console to be turned on and off
const logger = function() {
  let oldConsoleLog: any = null;
  const pub: any = {};

  pub.enable = function enableLogger() {
    if (oldConsoleLog == null) {
      return;
    }

    window['console']['log'] = oldConsoleLog;
  };

  pub.disable = function disableLogger() {
    oldConsoleLog = console.log;
    window['console']['log'] = function() {
      return true;
    };
  };

  return pub;
}();

// window.DEBUG makes DEBUG a global variable used throughout the code stack
window.DEBUG = {
  aInternal: 10,
  /* eslint-disable-next-line*/
  aListener: function(val?:any) {},
  set LOG(val: boolean) {
    this.aInternal = val;
    this.aListener(val);
  },
  get LOG(): boolean {
    return this.aInternal;
  },
  registerListener: function(listener?: any) {
    this.aListener = listener;
  },
};
window.DEBUG.registerListener(function(val: any) { // When the variable is set / get (changed)
  if (val) {
    logger.enable();
  } else {
    logger.disable();
  }
});

if (process.env.NODE_ENV == 'production' ) { // In prod env
  logger.disable(); // Mute console log
  
}

Vue.filter('capitalise', capitalise);
Vue.filter('titleFormat', titleFormat);
Vue.filter('dateFormat', dateFormat);
Vue.filter('dateInString', dateInString);
Vue.filter('daysRemaining', daysRemaining);
Vue.filter('currencySymbol', currencySymbol);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
