import {format, parseISO} from 'date-fns';

const dateFormat = function(value: any) {
  if (value && typeof value === 'string') {
    return format(parseISO(value), 'dd-MM-yyyy');
  } else if (value !== null)  {
    return format(value, 'dd-MM-yyyy');
  }
  return '-'
};

export default dateFormat