











import { Store } from '@/store/store';
export default class Footer extends Store {

  
}
