export const MockHandler: any =  {
    actions : {
        setSVA({commit}: any){
            commit('set_sva')
        },
        setDist({commit}: any){
            commit('set_dist')
        },
        setRes({ commit }: any) {
            commit('set_res')
        },
        setStatic({commit}: any){
            commit('set_static')
        },
        setDynamic({commit}: any){
            commit('set_dynamic')
        },
        reset({commit}: any){
            commit('reset')
        }
    },
    state: {
      example: '',
      dynamic: '',
      static: ''
    },
    mutations: {
        set_sva(state: typeof MockHandler.state){
            state.example = 'example=SimplyVideoAdmin'
        },
        set_res(state: any) {
            state.example = 'example=Reseller'
        },
        set_dist(state: any){
            state.example = 'example=Distributor'
        },
        set_static(state: any){
            state.dynamic = "dynamic=false"
        },
        set_dynamic(state: any){
            state.dynamic = "dynamic=true"
        },
        reset(state: any){
            state = {
                example: '',
                dynamic: '',
                static: ''
            }
        }
    },
    getters: {
      
    },
    namespaced: true
  };