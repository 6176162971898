import VueRouter, {RouteConfig} from 'vue-router';
const AdminLandingView = () => import ( '../SimplyVideoAdmin/views/Home.vue')
const LoginView = () => import ( '../views/Login.vue')
const AdminProfileView = () => import ( '../SimplyVideoAdmin/views/Profile.vue')
const AdminSettingsView = () => import ( '../SimplyVideoAdmin/views/Settings.vue')
const LicenseCreatorView = () => import ( '../SimplyVideoAdmin/components/LicenseCreator.vue')
const SimplyVideoAdminRegisterView = () => import ( '../SimplyVideoAdmin/views/Register.vue')
const PartnerRegistrationView = () => import ( '../views/Registration.vue')
const ForgotPasswordView = () => import ( '../views/ForgotPassword.vue')
const ResetPasswordView = () => import ( '../views/ResetPassword.vue')
const ApplicationsView = () => import ( '../SimplyVideoAdmin/views/Applications.vue')
const EditAccountView = () => import ( '../SimplyVideoAdmin/views/EditAccount.vue')
const LicenseManagerSimplyVideoAdminView = () => import ( '../SimplyVideoAdmin/LicenseManager.vue')
// Distributors
const DistiLandingView = () => import ( '../Distributor/views/Home.vue')
const CreateResellerView = () => import ( '../Distributor/views/CreateReseller.vue')

const DistiProfileView = () => import ( '../Distributor/views/Profile.vue')
const DistiEditResellerAccountView = () => import ( '../Distributor/views/EditResellerAccount.vue')
const DistiBillingView = () => import ( '../Distributor/views/BillingDetails.vue')
const OrderHistoryView = () => import('../Distributor/views/OrderHistory.vue')
const DistributorFinishSetupView = () => import('../Distributor/views/Registration.vue')
// Reseller
const ResellerLandingView = () => import('../Reseller/views/Home.vue')
const ResellerRegistrationView = () =>  import ( '../Reseller/views/Registration.vue')
//Customers
const CreateCustomerAccountView = () => import ( '../Customer/views/CreateAccount.vue')
const EditCustomerAccountView = () => import ( '../Customer/views/EditAccount.vue')

import store from '@/store';
import { Types } from '@/store/store'
export const SimplyVideoAdminLabel = 'sv_admin'
export const distributorLabel = 'distributor'
export const resellerLabel = 'reseller'

export type IRouteConf = {
  public?: boolean;
  accountType: ['sv_admin' | 'distributor' | 'reseller']; // This type of account can access the relevant route
}

// PROTECTED
export const Home: RouteConfig = {
  path: '/dashboard',
  name: 'Home',
  component: {
    render: (h) => {
      switch (store.getters.typeOfAccount) {
        case Types.Role.SvAdmin:
          return h(AdminLandingView)
        case Types.Role.Distributor:
          return h(DistiLandingView)
        case Types.Role.Reseller:
          return h(ResellerLandingView)
        default:
          return h(LoginView)
      }
    },
  },
};

export const Settings: RouteConfig = {
  path: '/settings',
  name: 'Settings',
  component: {
    render: (h) => {
      switch (store.getters.typeOfAccount) {
        case Types.Role.SvAdmin:
          return h(AdminSettingsView)
        default:
          return h(LoginView)
      }
    },
  },
};

export const Profile: RouteConfig = {
  path: '/profile',
  name: 'Profile',
  component: {
    render: (h) => {
      switch (store.getters.typeOfAccount) {
        case Types.Role.SvAdmin:
          return h(AdminProfileView)
        case Types.Role.Distributor:
          return h(DistiProfileView)
        default:
          return h(Home)
      }
    }
  },
};
export const LicenseCreator: RouteConfig = {
  path: '/new_license',
  name: 'License Creator',
  component: {
    render: (h) => {
      switch (store.getters.typeOfAccount) {
        case Types.Role.SvAdmin:
          return h(LicenseCreatorView)
      
        default:
          return h(Home)
      }
    }
  },
};

export const Applications: RouteConfig = {
  path: '/applications',
  name: 'Pending Applications',
  component: {
    render: (h) => {
      switch (store.getters.typeOfAccount) {
        case Types.Role.SvAdmin:
          return h(ApplicationsView)

        default:
          return h(Home)
      }
    }
  },
}

export const EditAccount: RouteConfig = {
  path: '/channel/account/:id',
  name: 'View & Edit Account',
  component: {
    render: (h) => {
      switch (store.getters.typeOfAccount) {
        case Types.Role.SvAdmin:
          return h(EditAccountView)
        case Types.Role.Distributor:
          return h(DistiEditResellerAccountView)
        default:
          return h(Home)
      }
    }
  },
}

export const LicenseManager: RouteConfig = {
  path: '/:type/user/:id/account/:account_id/licenses',
  name: 'License Manager',
  component: {
    render: (h) => {
      switch (store.getters.typeOfAccount) {
        case Types.Role.SvAdmin:
          case Types.Role.Distributor:
          return h(LicenseManagerSimplyVideoAdminView)
          // return h(LicenseManagerDistributorView)
      
        default:
          return h(Home)
      }
    }
  },
}

export const CreateReseller: RouteConfig= {
  path: '/create-reseller',
  name: 'Create Reseller',
  component: {
    render: (h) => {
      switch (store.getters.typeOfAccount) {
        case Types.Role.Distributor:  
          return h(CreateResellerView)
        default:
          return h(Home)
      }
    }
  },
};

export const BillingDetails : RouteConfig = {
  path: '/billing',
  name: 'Billing Details',
  component: {
    render: (h) => {
      switch (store.getters.typeOfAccount) {
        case Types.Role.Distributor:
            return h(DistiBillingView)
        default:
          return h(Home)
      }
    }
  },
}

export const EditCustomerAccount: RouteConfig = {
  path: '/customer/account/:id',
  name: 'View & Edit Customer Account',
  component: {
    render: (h) => {
      switch (store.getters.typeOfAccount) {
        case Types.Role.SvAdmin:
        case Types.Role.Distributor:
        case Types.Role.Reseller:
          return h(EditCustomerAccountView)
        default:
          return h(Home)
      }
    }
  },
}

export const OrderHistory: RouteConfig = {
  path: '/order-history',
  name: 'View Past Invoices',
  component: {
    render: (h) => {
      switch (store.getters.typeOfAccount) {
        case Types.Role.Distributor:
          return h(OrderHistoryView)
        default:
          return h(Home)
      }
    }
  },
}

// PUBLIC

export const Login: RouteConfig = {
  path: '/login',
  name: 'Login',
  component: LoginView,
  meta: {
    public: true,
  },
};

export const SimplyVideoAdminUserRegister: RouteConfig = {
  path: '/register-sv-admin/:token',
  name: 'Register',
  component: SimplyVideoAdminRegisterView,
  meta: {
    public: true,
  },
};

export const PartnerRegistration: RouteConfig = {
  path: '/distributor-registration', // Do not change as this is used in extenral sites to point to this site
  name: 'Partner Registration',
  component: PartnerRegistrationView,
  meta: {
    public: true
  }
}

export const ResellerRegister: RouteConfig = {
  path: '/register-reseller/:code',
  name: 'Reseller Registration',
  component: ResellerRegistrationView,
  meta: {
    public: true
  }
}

export const CreateCustomerAccount: RouteConfig = {
  path: '/customer/account',
  name: 'Create Customer Account',
  component: {
    render: (h) => {
      switch (store.getters.typeOfAccount) {
        case Types.Role.Distributor:
        case Types.Role.Reseller:
          return h(CreateCustomerAccountView)
      
        default:
          return h(Home)
      }
    }
  },
  meta:{
    accountType: [
      distributorLabel,
      resellerLabel,
    ]
  }
}

export const DistributorFinishSetup: RouteConfig = {
  path: '/finish-distributor-setup/:code',
  name: 'Distributor Finish Account Setup',
  component: DistributorFinishSetupView,
  meta:{
    public: true
  }
}
export const ForgotPassword: RouteConfig = {
  path: '/forgot-password',
  name: 'Forgot Password',
  component: ForgotPasswordView,
  meta:{
    public: true
  }
}

export const ResetPassword: RouteConfig = {
  path: '/reset-password/:code',
  name: 'Reset Password',
  component: ResetPasswordView,
  meta:{
    public: true
  }
}

export const Root: RouteConfig = {
  path: '/',
  name: 'root',
  redirect: Home
};

const routes: Array<RouteConfig> = [ // Register your routes here
  Home,
  Settings,
  Profile,
  LicenseCreator,
  Applications,
  EditAccount,
  LicenseManager,
  CreateReseller,
  ResellerRegister,
  BillingDetails,
  OrderHistory,
  CreateCustomerAccount,
  EditCustomerAccount,
  Login,
  SimplyVideoAdminUserRegister,
  DistributorFinishSetup,
  PartnerRegistration,
  ForgotPassword,
  ResetPassword,
  Root,
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  // If page is not public and you're not logged in, go to Login
  if (!to.meta?.public && !store.getters.isLoggedIn) 
    next({ name: 'Login' })
  // If you are logged in and route to Login, go to the Dashboard instead
  else if (to.name == 'Login' && store.getters.isLoggedIn){
    next(Home)
  }
  // The page is public, is not Login, has no account type or you have the right account type, go to it
  else if(to.meta?.public){
    next()
  }
  // We assume they are logged in so we set the refreshtoken timer
  else{
    store.dispatch('autoRefreshToken')
    next()
  }
})

export default router;
