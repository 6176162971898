import {parseISO, differenceInDays} from 'date-fns';

const daysRemaining = function(date1: any, date2?: any) {
  // Use todays date
  date2 = date2 ? date2 : new Date()
  if (date1 && typeof date1 === 'string') {
    date1 = parseISO(date1)
  } 
  if (date2 && typeof date2 === 'string') {
    date2 = parseISO(date2)
  } 
  if (date1 !== null && date2 !== null)  {
    const diff = differenceInDays(date1, date2)
    return diff > -1 ? diff : 0
  }
  return '-'
};

export default daysRemaining