const currencySymbol = function(value: string): string | null {
  if (!value) return '';

  let entity = value
  switch(value){
    case 'GBP':
      entity = '&pound;'
      break
    case 'EUR':
      entity = '&euro;'
      break
    case 'USD':
      entity = '&#36;'
      break
  }

  const parser = new DOMParser();
  const dom = parser.parseFromString('<!doctype html><body>' + entity, 'text/html');
  return dom.body.textContent;
}

export default currencySymbol