import {format, parseISO} from 'date-fns';

const dateInString = function(value: string) {
  const index = value.indexOf('20')
  if (index > -1) {
    const oldDate = value.substr(index, 10)
    const newDate = format(parseISO(oldDate), 'dd-MM-yyyy');
    return value.replace(oldDate, newDate)
  }
  return value
};

export default dateInString