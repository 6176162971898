



























































































































// @vuese
// @group Global
// Sidebar
import {
  Home, 
  Profile, 
  LicenseCreator, 
  Settings, 
  Applications,

  BillingDetails,
  OrderHistory,
  CreateReseller,
  CreateCustomerAccount,

  SimplyVideoAdminLabel,
  distributorLabel,
  resellerLabel
} from '../router';

/**
 * Basic sidebar that is used for quick navigation around the platform
 */
import {Store} from '@/store/store'
import Component from 'vue-class-component'

@Component
export default class SideBar extends Store {
  name = 'SideBar';

    Home  = Home
    Profile  = Profile
    LicenseCreator  =  LicenseCreator
    Settings  =  Settings
    Applications  = Applications
    
    BillingDetails  = BillingDetails
    CreateReseller  = CreateReseller
    OrderHistory = OrderHistory
    CreateCustomerAccount = CreateCustomerAccount

  async mounted() {
    await this.getCurrencies()
    await this.getCountries()
  }
  
  /**
   * user is SimplyVideo Admin
   */
  get admin(){      
    return this.typeOfAccount == SimplyVideoAdminLabel
  }
  /**
   * user is Distributor
   */
  get distributor(){
    return this.typeOfAccount == distributorLabel
  }

  /**
   * user is Reseller
   */
  get reseller(){
    return this.typeOfAccount == resellerLabel
  } 
  
  /**
   * Logout the user
   * @vuese
   */
  logOut() {
    this.logout()
  }
}
