


























import Vue from 'vue';
import { MetaInfo } from 'vue-meta';
import SideBar from './components/Sidebar.vue';
import NavBar from './components/Navbar.vue';
import Footer from './components/Footer.vue';
import Debug from './views/debug.vue'
import { Store } from './store/store';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { Login } from '@/router'

@Component({
  metaInfo(): MetaInfo {
    return {
      title: 'Welcome',
      titleTemplate: 'SimplyVideo Portal | %s',
    };
  },
  components: {
    SideBar,
    NavBar,
    Footer,
    Debug
  },

})
export default class App extends Store {
  name = 'App'
  componentTitle ='Your Profile'
  showDebug = false
  env = process.env.NODE_ENV
  @Watch('isLoggedIn')
  async userAuthChanged() {
    if(this.isLoggedIn){
      this.$toast.success('Successfully logged in')
    }
    else{
      this.$toast.success('Successfully logged out')
      this.$router.push(Login)
    }
  }
  get showSidebar(){
    //@ts-ignore
    return !this.$route.meta.public
  }
  async mounted() {
    if(process.env.NODE_ENV === 'staging'){
      //@ts-ignore
      window.Userback = window.Userback || {};
      //@ts-ignore
      Userback.access_token = '30569|45370|dAXj7L1TJsTPsbRDupQKJZCSY';
      (function(d) {
      var s = d.createElement('script');s.async = true;
      s.src = 'https://static.userback.io/widget/v1.js';
      (d.head || d.body).appendChild(s);
      })(document);
    }
    this.initApi()
    await this.getCurrencies()
    await this.getCountries()
  }

  showError(error: string){
    if(process.env.NODE_ENV !== 'production'){
      // If not production envrionment, show actual error
      this.$toast.error(error)
    }
    else{
      // In production environment give generic error message
      this.$toast.error('Oh No, There Was An Error 😢')
    }
  }
}
